<template>
  <div>
    <v-container>
      <p>
        The pipet in the picture below is referred to as a disposable pipet. Which of the following
        statements are true about this piece of lab equipment?
      </p>

      <v-img :src="image" class="mb-5 ml-4" max-width="289px" alt="Image of a disposable pipet" />

      <p v-for="option in optionsShuffled" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.input1" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-container>
  </div>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'UCIrvineQuiz1S1Q7',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        {
          text: 'This pipet should be returned to the box where you got it after use.',
          value: 'a',
        },
        {
          text: 'This pipet should be used to transfer the last bit of solvent to acquire a desired volume.',
          value: 'b',
        },
        {
          text: 'When finished with the pipet, all liquid should be expelled and then the pipet should be placed in the trash.',
          value: 'c',
        },
        {
          text: 'This pipet should not be used to measure precise volumes',
          value: 'd',
        },
      ],
    };
  },
  computed: {
    image() {
      return '/img/assignments/UCIrvine/disposable-pipette.png';
    },
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
